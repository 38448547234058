import BodyComponent from "./BodyComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";

const MainComponent = () => {
    return(
        <div className="wrapper">
           <HeaderComponent />
           <BodyComponent />
           <FooterComponent />
        </div>
    );
};

export default MainComponent;