const HeaderComponent = () => {
    return (
        <header className="header">
            <div className="header-wrap container-big">
                <nav className="header-nav">
                    <ul data-v-a0ae22c2="" className="header-nav-list">
                        <li data-v-a0ae22c2="">
                            <a data-v-a0ae22c2="" href="/" aria-current="page" className="router-link-exact-active router-link-active">
                                Главная
                            </a>
                        </li> 
                        <li data-v-a0ae22c2="">
                            <a data-v-a0ae22c2="" href="https://t.me/YdChange_24" rel="noreferrer" target="_blank" className="menu-channel">
                                <svg data-v-a0ae22c2="" width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path data-v-a0ae22c2="" d="M6.78047 14.65L7.06047 10.42L14.7405 3.49997C15.0805 3.18997 14.6705 3.03997 14.2205 3.30997L4.74047 9.29997L0.640471 7.99997C-0.239529 7.74997 -0.249529 7.13997 0.840471 6.69997L16.8105 0.539968C17.5405 0.209968 18.2405 0.719968 17.9605 1.83997L15.2405 14.65C15.0505 15.56 14.5005 15.78 13.7405 15.36L9.60047 12.3L7.61047 14.23C7.38047 14.46 7.19047 14.65 6.78047 14.65Z" />
                                </svg>
                                Наш телеграм   
                            </a>
                        </li> 
                    </ul>
                </nav> 
                <nav className="header-tg">
                    <a href="https://t.me/YdChange_24" rel="noreferrer" target="_blank" className="menu-channel">
                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.78047 14.65L7.06047 10.42L14.7405 3.49997C15.0805 3.18997 14.6705 3.03997 14.2205 3.30997L4.74047 9.29997L0.640471 7.99997C-0.239529 7.74997 -0.249529 7.13997 0.840471 6.69997L16.8105 0.539968C17.5405 0.209968 18.2405 0.719968 17.9605 1.83997L15.2405 14.65C15.0505 15.56 14.5005 15.78 13.7405 15.36L9.60047 12.3L7.61047 14.23C7.38047 14.46 7.19047 14.65 6.78047 14.65Z" />
                        </svg>
                    </a>
                </nav> 
            </div>
        </header>
    );
}

export default HeaderComponent;