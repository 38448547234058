import yochange from '../assets/yochange-logo.png';

const FooterComponent = () => {
    return (
        <footer class="footer">
            <div class="footer-wrap container-big">
                <nav class="footer-nav">
                    <ul data-v-a0ae22c2="" class="footer-nav-list">
                        <li data-v-a0ae22c2="">
                            <a data-v-a0ae22c2="" href="/" class="router-link-exact-active router-link-active" aria-current="page">
                                Главная
                            </a>
                        </li> 
                        <li data-v-a0ae22c2="">
                            <a data-v-a0ae22c2="" href="https://t.me/YdChange_24" rel='noreferrer' target="_blank" class="menu-channel">
                                <svg data-v-a0ae22c2="" width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path data-v-a0ae22c2="" d="M6.78047 14.65L7.06047 10.42L14.7405 3.49997C15.0805 3.18997 14.6705 3.03997 14.2205 3.30997L4.74047 9.29997L0.640471 7.99997C-0.239529 7.74997 -0.249529 7.13997 0.840471 6.69997L16.8105 0.539968C17.5405 0.209968 18.2405 0.719968 17.9605 1.83997L15.2405 14.65C15.0505 15.56 14.5005 15.78 13.7405 15.36L9.60047 12.3L7.61047 14.23C7.38047 14.46 7.19047 14.65 6.78047 14.65Z" />
                                </svg>
                                Наш телеграм
                            </a>
                        </li> 
                    </ul>
                </nav> 
                <div className="footer-company-info">
                Полное название организации  ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ПРО ОБМЕН" <br/><br/>
                Английское название  OOO "PRO OBMEN"<br/><br/>
                Адрес  г. Москва, вн.тер.г. муниципальный округ Соколиная гора, ул Щербаковская, д. 3, помещ. 2/1<br/><br/>
                Уставный капитал  10 000 <br/><br/>
                ОКФС  Частная собственность<br/><br/>
                ОКОПФ  Общества с ограниченной ответственностью<br/><br/>
                ОКОГУ  Организации, учрежденные юридическими лицами или гражданами, или юридическими лицами и гражданами совместно<br/><br/>
                ИНН  7720419849<br/><br/>
                ОГРН  1187746383593<br/><br/>
                КПП  771901001<br/><br/>
                ОКАТО  Город Москва столица Российской Федерации город федерального значения, Восточный, Соколиная гора<br/><br/>
                ОКПО  28183060
                </div>
                <div className="footer-big-wrapper">
                    <div class="footer-logo">
                        <a href="/">
                            <img src={yochange} alt="YdChange" />
                        </a>
                    </div> 
                    <div class="footer-copyright">
                        <div class="footer-text">
                            <div data-v-c78208b0="" class="year-component">
                                © 2024 Все права защищены
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent;